import React from 'react'

import Layout from "../components/layout"
import Head from '../components/head'

const ContactPage = () => {
    return (
        <React.Fragment>
        <Layout>
            <Head title = "Contact"/>
            <h1>Contact</h1>
            <p>
                The best way to reach me is via <a href="https://www.linkedin.com/in/mesbah-rafin-2a916b140/" target="_blank">Mesbah Rafin</a> on Linkedin!
            </p>
        </Layout>
        </React.Fragment>
    )
}

export default ContactPage